
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        





























































.blog-single {
  .wysiwyg__content {
    width: 100%;
    margin-bottom: $spacing * 1.5;
  }

  .faq-outer {
    background-color: transparent;
  }

  .faq {
    padding: 0;
  }

  ::v-deep blockquote {
    position: relative;
    margin: 5em 3rem 3em;
    font-size: 3.2rem;
    text-align: center;

    &::before {
      @include center-x();

      top: -6rem;
      content: url('../shared/inc/svg/icons/quote.svg');
      display: block;
      width: 3.5rem;
      height: 3.1rem;
    }
  }

  ::v-deep small {
    color: $gray-light;
  }
}

.blog-single-inner {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  padding-bottom: $spacing * 4;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  @include mq(m) {
    display: block;
  }
}

.blog-single__main {
  // max-width: 72rem;
  font-family: $ff-default;
  font-weight: 400;

  b {
    color: $c-gray-darkest;
  }

  @include mq(m) {
    float: right;
    width: inner-col(12, 9);
    margin-left: inner-col(12, 1);
  }
}

.blog-single__aside {
  flex-shrink: 0;
  width: 100%;

  @include mq(m) {
    position: sticky;
    top: 2rem;
    width: inner-col(12, 2);
  }

  @include mq($until: l) {
    top: 8rem;
  }
}

.blog-aside {
  position: relative;
  top: auto;
  width: 100%;
}

.faq__listing {
  width: 100%;
}

.faq-item {
  @extend %box-shadow;
}
