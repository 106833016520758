
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































































.blog-aside {
  position: sticky;
  top: 2rem;
  font-family: $ff-default;
  font-weight: 400;
}

.blog-aside__back {
  display: flex;
  align-items: center;
  text-decoration: none;

  .icon {
    transform: rotate(90deg);
    margin-right: 1rem;
  }
}

.blog-aside__share {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing * 1.5;
  padding-top: $spacing * 1.5;
  border-top: 1px solid $c-gray-lightest;
}

.blog-aside__update {
  color: $c-blue-dark;
  opacity: 0.5;
}

.blog-aside__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing * 2;
  padding-top: $spacing;
  border-top: 1px solid $c-gray-lightest;

  @include mq($until: m) {
    margin-bottom: $spacing * 2;
  }
}

.blog-aside__tag {
  @extend %fw-bold;
  @extend %text-uppercase;

  margin-bottom: $spacing / 2;
  margin-right: $spacing / 2;
  padding: $spacing / 2 $spacing;
  color: $c-gray;
  font-family: $ff-alt;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid $c-gray-lightest;
  border-radius: 0.5rem;

  @include mq(l) {
    font-size: 1.2rem;
  }
}
